import {Auth} from "aws-amplify";


export async function authenticationLoader() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return {user}
  } catch (error) {
    return {error};
  }
}