import {Authenticator} from '@aws-amplify/ui-react';
import {Amplify} from "aws-amplify";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import routes from './config/routes';
import awsExports from "./config/aws-exports";

import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsExports);

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <Authenticator.Provider>
      <RouterProvider router={router}/>
    </Authenticator.Provider>
  );
}

export default App;