import React from 'react';
import {View} from '@aws-amplify/ui-react';
import styles from './index.module.css';
import Navbar from "../Navbar";
import Footer from "../Footer";


const MinimalLayout = ({children}) => (
    <>
        <Navbar/>
        <View
            className={styles.contentView}
        >
            {children}
            <Footer/>
        </View>
    </>
)

export default MinimalLayout;