import React from "react";
import VisibleContainer from '../../components/VisibleContainer';
import CookiePolicyScript from "../../components/CookiePolicyScript";

export default function Home() {

  return (
    <VisibleContainer>
      <CookiePolicyScript />
    </VisibleContainer>
  );
}