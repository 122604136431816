import React from "react";
import {Flex, Menu, MenuButton, MenuItem, useAuthenticator, useTheme} from "@aws-amplify/ui-react";
import {useLoaderData, useNavigate, Link} from "react-router-dom";
import {AiOutlineMenu} from "react-icons/ai";

const Navbar = () => {
  const {route, signOut} = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  const {tokens} = useTheme();

  const {user} = useLoaderData();
  const {username = "Profilo"} = user || {};

  async function logOut() {
    signOut();
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="#000"
      padding={`${tokens.space.small} ${tokens.space.xl}`}
    >
      <Link
        to="/"
      >
        <h1>MyEdu Admin</h1>
      </Link>
      {
        route === 'authenticated' &&
        <Menu
          menuAlign="end"
          trigger={
            <MenuButton style={{
              border: "none",
              color: "#fff"
            }}>
              <AiOutlineMenu/>
            </MenuButton>
          }
        >
          <MenuItem
            onClick={() => navigate('/utente')}
          >
            {username}
          </MenuItem>
          <MenuItem
            onClick={logOut}
          >
            Logout
          </MenuItem>
        </Menu>
      }
    </Flex>
  )
}

export default Navbar;