import {Card, Table, TableBody, TableRow, TableCell, Heading} from "@aws-amplify/ui-react";
import {useLoaderData} from "react-router-dom";
import React from "react";
import GlobalAlert from "../../components/GlobalAlert";

const User = () => {
  const {
    user,
    error,
  } = useLoaderData();

  const {signInUserSession = {}} = user || {};
  const {idToken = {}} = signInUserSession || {};
  const {payload: userData} = idToken || {};

  const displayValue = value => {
    switch (true) {
      case value.constructor === Array:
        return value.join(", ");
      default:
        return value;
    }
  }

  return (
    <>
      {
        !!error &&
        <GlobalAlert
          variation="error"
          heading={error.toString()}
        />
      }
      <Card
        variation="outlined"
        style={{
          maxWidth: 1200,
          margin: "32px auto",
        }}
      >
        <Heading level={1}>
          Utente
        </Heading>
        <Table
          caption=""
          highlightOnHover={false}
          size="small"
          style={{
            margin: 16,
          }}
          variation="striped"
        >
          <TableBody>
            {
              Object.keys(userData).map(
                key => {
                  const value = userData[key];

                  if (!value) {
                    return null;
                  }

                  return (
                    <TableRow
                      key={key}
                    >
                      <TableCell>
                        <b>{key}</b>
                      </TableCell>
                      <TableCell>
                        {displayValue(value)}
                      </TableCell>
                    </TableRow>
                  )
                }
              )
            }
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

export default User