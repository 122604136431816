import {useLoaderData} from 'react-router-dom';
import awsExports from "../config/aws-exports";
import {buildLoginUrl} from "../utils";

export default function RequireAuth({children}) {
  const {Auth = {}} = awsExports || {};
  const {oauth = {}} = Auth || {};
  const {
    domain = "",
    responseType: response_type = "",
    scope = [],
    redirectSignIn: redirect_uri = "",
  } = oauth || {};

  const loginUrl = buildLoginUrl(
    domain,
    scope,
    {
      response_type,
      redirect_uri
    }
  );

  const {
    user,
    // TODO display error
    // error,
  } = useLoaderData();

  if (!user) {
    window.location.href = loginUrl;
    return null;
  }

  return children;
}