import React from "react";
import styles from './index.module.css';
import {Text, useTheme, View} from "@aws-amplify/ui-react";

const Footer = () => {
  const {tokens} = useTheme();

  return (
      <View
          textAlign="center"
          padding={tokens.space.large}
          className={styles.footer}
      >
        <Text className={styles.footerText}>
          <a href="https://myedu.it" target="_blank" rel="noreferrer">Privacy Policy</a>&nbsp;|&nbsp;
          <a href="/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a><br/>
          <span>Copyright 2023 FME Education S.p.A - P.I. 08233380966</span>
        </Text>
      </View>
  );
}

export default Footer;