import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from "../Footer";
import Navbar from "../Navbar";

import './index.module.css';

const Layout = () => {

  return (
    <React.Fragment>
      <Navbar/>
      <Outlet/>
      <Footer/>
    </React.Fragment>
  );
}

export default Layout;