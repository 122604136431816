import Layout from "../components/Layout";
import RequireAuth from "../components/RequireAuth";
import ErrorPage from "../pages/ErrorPage";
import CookiePolicy from "../pages/CookiePolicy"
import User from "../pages/User";
import {authenticationLoader} from "../data/loaders";
import {Navigate} from "react-router-dom";
import Home from "../pages/Home";

const routes = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <Layout/>
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    loader: authenticationLoader,
    children:
      [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/utente',
          element: <User/>,
          loader: authenticationLoader,
        },
        {
          path: '/cookie-policy',
          element: <CookiePolicy/>,
        },
        {
          path: '*',
          element: <Navigate to="/" />,
        },
      ],
  },
];

export default routes;