import React from "react";
import {Alert} from "@aws-amplify/ui-react";
import styles from './index.module.css'

const GlobalAlert = (
  {
    heading,
    variation,
    children,
    ...rest
  }
) => {
  return (
    <Alert
      hasIcon
      isDismissible
      heading={heading}
      variation={variation}
      className={styles.globalAlert}
      {...rest}
    >
      {children}
    </Alert>
  )
}

export default GlobalAlert;