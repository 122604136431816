export const buildLoginUrl = (
  baseUrl = "",
  scope = [],
  rest = {},
) => {
  const params = {
    client_id: process.env.REACT_APP_AWS_AUTH_USER_POOL_WEBCLIENT_ID,
    ...rest,
  }

  const searchParams = new URLSearchParams(params).toString();

  return `https://${baseUrl}/login?${searchParams}&scope=${scope.join("+")}`;
}