import React from "react";
import sitesList from "../../data/sites-list";
import {Table, TableBody, TableCell, TableHead, TableRow, View} from "@aws-amplify/ui-react";
import {FaArrowDown, FaArrowRight} from "react-icons/fa"

const Home = () => {
  return (
    <View padding="32px" style={{minHeight: 'calc(100vh - 210px)'}}>
      <Table
        size="small"
        variation="bordered"
        highlightOnHover
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <b>
                <FaArrowDown/> Piattaforme
                &nbsp; | &nbsp;
                Ambienti <FaArrowRight/>
              </b>
            </TableCell>
            <TableCell>PRODUZIONE</TableCell>
            <TableCell>STAGING</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            sitesList.map(site => (
              <TableRow key={site.title}>
                <TableCell>
                  {site.title}
                </TableCell>
                <TableCell>
                  <a
                    href={site.prodLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {site.prodLink}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    href={site.stagingLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {site.stagingLink}
                  </a>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </View>
  )
}

export default Home;