import {useRouteError, useNavigate} from "react-router-dom";
import {Button, Grid, View} from "@aws-amplify/ui-react";
import MinimalLayout from "../../components/Layout/MinimalLayout";
import styles from "./index.module.css";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <MinimalLayout>
      <Grid
        templateColumns={{base: "1fr", medium: "2fr 1fr", large: "4fr 1fr"}}
        templateRows={{base: "repeat(5, auto)", medium: "repeat(4, auto"}}
        className={styles.mainGrid}
      >
        <View
          className={styles.titleViewWrapper}
          columnSpan={[1, 1, 2, 2]}
        >

          <h1>Oops!</h1>
          <p>Si è verificato un problema inatteso!</p>
          {
            process.env.REACT_APP_DEBUG &&
            <div
              className={styles.codeWrapper}
            >
              <code>
                {error.statusText || error.message}
              </code>
            </div>
          }
          <div
            className={styles.btnContainer}
          >
            <Button
              className={styles.backBtn}
              onClick={() => navigate("/")}
              variation="primary"
              size="large"
            >
              Torna alla home
            </Button>
          </div>
        </View>
      </Grid>
    </MinimalLayout>
  );
}