import React from 'react';
import {View} from "@aws-amplify/ui-react";
import styles from './index.module.css';

const visibleContainer = ({children}) => (
  <View
    className={styles.container}
  >
    {children}
  </View>
);

export default visibleContainer;