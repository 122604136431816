export default [
  {
    title: "MyEdu Coaching",
    stagingLink: "https://admin-staging.coaching.myedu.it",
    prodLink: "https://admin.coaching.myedu.it",
  },
  {
    title: "MyEdu Viviparchi",
    stagingLink: "https://admin-staging.viviparchi.myedu.it",
    prodLink: "https://admin.viviparchi.myedu.it",
  },
  {
    title: "MyEdu Editors",
    stagingLink: "https://admin-staging.editors.myedu.it",
    prodLink: "https://admin.editors.myedu.it",
  }
];